import './App.css'

import React from 'react'

import { Authenticator } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import { type AmplifyUser } from '@aws-amplify/ui/dist/types/types/authenticator/user'

async function callLambda (user: AmplifyUser): Promise<void> {
  console.log('calling lambda')
  let jwtToken = user.getSignInUserSession()?.getAccessToken().getJwtToken()
  if (jwtToken === undefined) {
    jwtToken = ''
  }
  console.log('bearer ' + jwtToken)
  const headers = { Authorization: 'Bearer ' + jwtToken }
  const lambdaUrl = 'https://rhei5lq2itophkex57wvqeuufq0mfarh.lambda-url.us-east-1.on.aws/'
  await fetch(lambdaUrl, { headers })
    .then(async response => await response.json())
    .then(data => { console.log(data) })
}

function App (): JSX.Element {
  return (
    <Authenticator>
      {({ signOut, user }) => {
        console.log(user)
        console.log(user)
        if (user != null) {
          return (
            <div>
              <p>
                Welcome
                {' '}
                {user.username}
              </p>
              <button type="button" onClick={() => { void callLambda(user) }}>Call Lambda</button>
              <button type="button" onClick={signOut}>Sign out</button>
            </div>
          )
        } else {
          return (
            <div>
              <p>
                Error retrieving user attributes
              </p>
              <button type="button" onClick={signOut}>Sign out</button>
            </div>
          )
        }
      }}
    </Authenticator>
  )
}

export default App
